import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import logo from "../assets/img/logo.png"
import instagramIcon from "../assets/img/instagram-icon.svg"
import facebookIcon from "../assets/img/facebook-icon.svg"
import $ from "jquery"

const Header = ({ siteTitle }) => {
  const { t } = useTranslation()
  const { changeLanguage, language } = useI18next()
  useEffect(() => {
    var header
    var base_url
    if (typeof document !== "undefined") {
      header = document.getElementById("header")
      base_url = window.location.origin
      base_url += base_url.endsWith("/") ? "" : "/"
      if (
        window.location.href.split("#")[0] === base_url ||
        window.location.href.split("#")[0].endsWith("en/")
      ) {
        $(window).scroll(function () {
          var scroll = $(window).scrollTop()
          if (scroll >= 50) {
            header.classList.add("scrolled")
          } else {
            header.classList.remove("scrolled")
          }
        })
      }
    }
    if ($(window).width() < 1280) {
      $(".nav li a").click(function () {
        $("ul.nav").removeClass("no-search")
        $("ul.nav").removeClass("mobile-nav")
        $(".menu-toggle").removeClass("is-active")
      })
    }
  }, [])
  var menuBtn
  if (typeof document !== "undefined") {
    menuBtn = document.getElementById("mobile-menu")
  }
  var menuNav
  if (typeof document !== "undefined") {
    menuNav = document.getElementById("nav")
  }
  function openMenu() {
    menuBtn.classList.toggle("is-active")
    menuNav.classList.toggle("mobile-nav")
  }
  let isHomePage
  if (typeof window !== "undefined") {
    isHomePage =
      window.location.pathname === "/" || window.location.pathname === "/en/"
  }

  return (
    <header id="header">
      <div className="header-container">
        <div className="nav-wrapper">
          <nav className="navbar">
            <Link to="/">
              <img src={logo} alt="Onur Otel" />
            </Link>
            <div className="menu-toggle" id="mobile-menu" onClick={openMenu}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>
            <ul className="nav no-search" id="nav">
              <li className="nav-item menu-item">
                <Link to={`${isHomePage ? "" : "/"}#aboutHotel`}>
                  {t("h-aboutHotel")}
                </Link>
              </li>
              <li className="nav-item menu-item">
                <Link to={`${isHomePage ? "" : "/"}#rooms`}>
                  {t("h-rooms")}
                </Link>
              </li>
              <li className="nav-item menu-item">
                <Link to={`${isHomePage ? "" : "/"}#gallery`}>
                  {t("h-gallery")}
                </Link>
              </li>
              <li className="nav-item menu-item">
                <Link to={`${isHomePage ? "" : "/"}#sustainability`}>
                  {t("h-sustainability")}
                </Link>
              </li>
              <li className="nav-item menu-item">
                <Link to={`${isHomePage ? "" : "/"}#contact`}>
                  {t("h-contact")}
                </Link>
              </li>
              <li className="nav-item">
                <div className="lang-selector">
                  <div
                    className={`changeLang ${language === "tr" && "active"}`}
                    onClick={() => changeLanguage("tr")}
                  >
                    TR
                  </div>
                  <div
                    className={`changeLang ${language === "en" && "active"}`}
                    onClick={() => changeLanguage("en")}
                  >
                    EN
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <div className="social-media">
                  <a
                    href="https://www.instagram.com/onurhotelyalikavak/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={instagramIcon} alt="" />
                  </a>
                  <a
                    href="https://www.facebook.com/OnurBoutiqueHotel"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={facebookIcon} alt="" />
                  </a>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Header
