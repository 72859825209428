import React from "react"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import logo from "../assets/img/logo.png"
import instagramIcon from "../assets/img/instagram-icon.svg"
import facebookIcon from "../assets/img/facebook-icon.svg"

library.add(fab)

export default function Footer() {
  const { t } = useTranslation()
  return (
    <footer>
      <div className="footer-container">
        <img src={logo} alt="" id="footer-logo" />
        <span>© {new Date().getFullYear()}{t("footerCopyright")}</span>
        <div className="f-social-media">
          <a
            href="https://www.instagram.com/onurhotelyalikavak/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagramIcon} alt="" />
          </a>
          <a
            href="https://www.facebook.com/OnurBoutiqueHotel"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebookIcon} alt="" />
          </a>
        </div>
      </div>
    </footer>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
